import { Box, Divider, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, RefObject, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import Button from '../../components/Button/Button';
import { FormattedMessage, IconGemFilled, NamedLink, SearchResultsPanel } from '../../components';
import Banner from '../../components/Banner/Banner';
import IconSearchSubscribe from '../../components/Icons/IconSearchSubscribe/IconSearchSubscribe';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { setSavedSearchSource } from '../../ducks/user.duck';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useIsMobile } from '../../hooks/useIsMobile';
import { EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import { getLocalStorage, setLocalStorage } from '../../util/localStorageHelpers';
import { parse } from '../../util/urlHelpers';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import AppContext from '../../context/AppContext';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { LandingPageV2State } from './LandingPageV2.duck';
import { trackClickISOBanner, trackViewSearchResults } from '../../util/heap';
import TypographyWrapper, {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { FrenzyApiModes } from '../../types/frenzy/query';
import css from './LandingPageV2.module.css';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import RecentArrivalsCarousel from '../../components/RecentArrivalsCarousel/RecentArrivalsCarousel';
import { RecentArrivalsCarouselState } from '../../ducks/recentArrivalsCarousel.duck';
import { useIsShopSideLaunched } from '../../hooks/useIsShopSideLaunched';
import { MIN_CAROUSEL_LISTINGS } from '../../components/ListingItemsCarousel/ListingItemsCarousel';

interface SearchPanelProps {
  listingsAreLoaded: boolean;
  totalItems: number;
  areFiltersApplied: boolean;
  subscribeBannerActionScrollToRef: RefObject<htmlheadingelement>;
}

const RecentlyListedListingsSearchResults = () => {
  const { recentListings } = useSelector<any>(
    (state) => state.recentArrivalsCarousel
  ) as RecentArrivalsCarouselState;

  const isShopSideLaunched = useIsShopSideLaunched();

  // Render the divider only if the Recent Arrivals Carousel is displayed
  const displayDivider = isShopSideLaunched && recentListings.length >= MIN_CAROUSEL_LISTINGS;

  return (
    <box>
      {displayDivider && (
        <box my="{3}">
          <divider></divider>
        </box>
      )}
      <recentarrivalscarousel></recentarrivalscarousel>
    </box>
  );
};

const SearchPanel: FC<searchpanelprops> = (props) => {
  const { listingsAreLoaded, totalItems, areFiltersApplied, subscribeBannerActionScrollToRef } =
    props;

  const rootState = useSelector<any>((state) => state) as any;
  const { currentPageResultIds, pagination, searchListingsError } = useSelector<any>(
    (state) => state.LandingPageV2
  ) as LandingPageV2State;
  const listings = getListingsById(rootState, currentPageResultIds);

  const { treetId } = useContext(AppContext);
  const { shopName } = useShopConfigV2();
  const isISOEnabled = useFeatureFlags(Feature.InSearchOf);
  const { allowSell, isListTradeInOnly } = useEnabledCustomerExperiences();
  const isMobile = useIsMobile();
  const location = useLocation();
  const searchParams = parse(location.search);
  if (searchParams?.keywords && listingsAreLoaded) {
    trackViewSearchResults(totalItems, searchParams.keywords, treetId);
  }

  const hasResults = listingsAreLoaded && totalItems > 0;
  const hasNoResults = listingsAreLoaded && totalItems === 0;
  const shouldShowRecentArrivals = hasNoResults && !isEmpty(searchParams?.keywords);

  const dispatch = useDispatch();

  const { shouldAllowSearch: hasProductCatalog } = useAllowedFindItemMethods();

  const listItemLink = (
    <namedlink name="NewListingPage">
      <typography variant="body2" display="inline" className="{css.link}">
        {isListTradeInOnly ? 'trade in your item' : 'list your own item'}
      </typography>
    </namedlink>
  );

  const subscribeBannerLocalStorageId = `seenSizeSubscribeBanner-${treetId}`;

  const handleOnSubscribeBannerClose = () => setLocalStorage(subscribeBannerLocalStorageId, 'true');

  const handleSubscribeBannerActionClick = () => {
    setLocalStorage(subscribeBannerLocalStorageId, 'true');
    dispatch(setSavedSearchSource({ source: EmailSubscribeSource.SearchResultsBanner }));
    subscribeBannerActionScrollToRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const handleISOBannerClick = () => {
    trackClickISOBanner(treetId, shopName, pagination?.page || 1);
    dispatch(setActiveModal(ModalType.ISO));
    dispatch(setSavedSearchSource({ source: EmailSubscribeSource.SearchResultsBannerIso }));
  };

  const hasUserSeenSizeSubscribeBanner = getLocalStorage(subscribeBannerLocalStorageId) === 'true';

  const hasInteractedWithShop =
    (pagination && pagination!.page > 1) || areFiltersApplied || searchParams?.mode;

  const showSizeSubscribeBanner =
    !isISOEnabled &&
    ((hasNoResults && listingsAreLoaded) ||
      (hasInteractedWithShop && !hasUserSeenSizeSubscribeBanner));
  const sizeSubscribeBannerEl = showSizeSubscribeBanner && (
    <banner bodyText="Not seeing what you’re looking for?" actionText="Get notified when items are listed." icon="{<IconSearchSubscribe"></banner>}
      onClose={handleOnSubscribeBannerClose}
      onActionClick={handleSubscribeBannerActionClick}
      mb={isMobile ? 1 : 2}
    />
  );

  const isoBannerEl = isISOEnabled &&
    listingsAreLoaded &&
    hasProductCatalog &&
    // Display the banner if there are results or if there are no results on the landing page
    (hasResults || (hasNoResults && isEmpty(searchParams?.keywords))) && (
      <banner bodyText="Looking for a specific item?" actionText="Submit an item request." icon="{<IconGemFilled"></banner>}
        onActionClick={handleISOBannerClick}
        mb={isMobile ? 1 : 2}
      />
    );
  const subscribeBannerEl = isoBannerEl || sizeSubscribeBannerEl || undefined;

  const clearFiltersOrSearchLink = (
    <namedlink name="LandingPage" to="{{" state:="" {="" scrollOnRender:="" true="" },="" search:="" 'mode="raw-query'," }}="">
      <typographywrapper variant="body1" format="{TypographyFormat.Underlined}" weight="{TypographyWeight.Bold}">
        {searchParams.keywords ? 'Clear search' : 'Clear filters'}
      </typographywrapper>
    </namedlink>
  );

  return (
    <>
      <box 1="" 2="" px="{isMobile" ?="" :="" 8}="" py="{isMobile" 2}="">
        <div className="{css.searchResultsWrapper}">
          {!!searchListingsError && (
            <h2 className="{css.error}">
              <formattedmessage id="SearchPage.searchError"></formattedmessage>
            </h2>
          )}
          {subscribeBannerEl}
          {hasNoResults && !areFiltersApplied && (
            <div className="{css.noSearchResults}">
              <typography variant="body2">
                {!allowSell ? (
                  'All items have recently sold! Check back soon.'
                ) : (
                  <formattedmessage id="SearchFiltersPrimary.noResults" values="{{" listItemLink="" }}=""></formattedmessage>
                )}
              </typography>
            </div>
          )}
          {hasNoResults && areFiltersApplied && (
            <box my="{3}" display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
              <box my="{1}">
                <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
                  No results for{' '}
                </typographywrapper>
                <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline'="" }}="">
                  {`“${searchParams.keywords}”`}
                </typographywrapper>
              </box>
              <box my="{1}">
                <typographywrapper variant="body1">
                  But you can still submit an item request! We’ll find the exact item you have in
                  mind and let you know when it lists!
                </typographywrapper>
              </box>
              <box my="{1}">
                <button onClick="{handleISOBannerClick}">Submit An Item Request</button>
              </box>
              {clearFiltersOrSearchLink}
            </box>
          )}
          {hasResults && (
            <box mb="{2}" display="flex" flexDirection="column" alignItems="start">
              {searchParams.keywords ? (
                <>
                  <box>
                    <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
                      {`${totalItems} results for `}
                    </typographywrapper>
                    <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="" weight="{TypographyWeight.Bold}">
                      {`“${searchParams.keywords}”`}
                    </typographywrapper>
                  </box>
                  {clearFiltersOrSearchLink}
                </>
              ) : (
                searchParams.mode === FrenzyApiModes.FilterChange && (
                  <>
                    <box>
                      <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
                        {`${totalItems} results`}
                      </typographywrapper>
                    </box>
                    {clearFiltersOrSearchLink}
                  </>
                )
              )}
            </box>
          )}
          <div className="{classNames({" [css.newSearchInProgress]:="" !listingsAreLoaded,="" })}="">
            {shouldShowRecentArrivals && <recentlylistedlistingssearchresults></recentlylistedlistingssearchresults>}
            <searchresultspanel listings="{listings}" pagination="{pagination}" search="{searchParams}"></searchresultspanel>
          </div>
        </div>
      </box>
    </>
  );
};

const areEqual = (prevProps: SearchPanelProps, nextProps: SearchPanelProps) =>
  isEqual(prevProps, nextProps);

export default React.memo(SearchPanel, areEqual);
</any></any></searchpanelprops></any></htmlheadingelement>