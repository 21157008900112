import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { FieldRadioButton, FilterPlain, TypographyWrapper } from '..';
import config from '../../shopConfig/config';
import FilterPopupV2 from '../FilterPopupV2/FilterPopupV2';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useTypographyStyles } from '../../hooks/useTypographyStyles';
import { FrenzySortOptions, SortConfig, SortOption } from '../../types/filters/filters';
import { Feature } from '../../util/featureFlags';

import css from './SortByRadio.module.css';
import { LandingPageV2State } from '../../containers/LandingPageV2/LandingPageV2.duck';

interface SortByRadioProps {
  urlQueryParams: { [key: string]: string[] | string | undefined };
  sortConfig: SortConfig;
  isConflictingFilterActive: boolean;
  onSubmit: (values: { [key: string]: string | null }) => void;
  showAsPopup: boolean;
}

interface GroupOfFieldRadioButtonsProps {
  id: string;
  className: string;
  name: string;
  options: SortOption[];
}

const formatQueryParam = (selectedOption: string, queryParamName: string) => ({
  [queryParamName]: selectedOption || null,
});

const optionLabel = (options: SortOption[], key: string) => {
  const option = options.find((o: SortOption) => o.key === key);
  return option ? option.label : key;
};

const GroupOfFieldRadioButtons: FC<groupoffieldradiobuttonsprops> = (props) => {
  const { id, className, name, options } = props;

  const typographyClasses = useTypographyStyles({
    hoverStyles: { opacity: '0.6' },
    activeStyles: { opacity: '0.6' },
    disabledStyles: { opacity: '0.3', cursor: 'default' },
  })();

  return (
    <fieldset className="{className}">
      <ul className="{css.list}">
        {options.map((option) => {
          const fieldId = `${id}.${option.key}`;
          const isOptionDisabled = option.disabled;
          return (
            <li key="{fieldId}" className="{css.item}">
              <fieldradiobutton id="{fieldId}" name="{name}" label="{option.label}" value="{option.key}" disabled="{isOptionDisabled}" typographyOverrides="{{" className:="" classNames(typographyClasses.root,="" {="" disabled:="" isOptionDisabled="" }),="" }}=""></fieldradiobutton>
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const SortByRadio: FC<sortbyradioprops> = (props) => {
  const { urlQueryParams, sortConfig, onSubmit, showAsPopup, isConflictingFilterActive } = props;
  const { queryParamName } = sortConfig;
  const isFrenzySearchEnabled = useFeatureFlags(Feature.FrenzySearch);
  const { searchApiFilters } = useSelector<any>(
    (state) => state.LandingPageV2
  ) as LandingPageV2State;
  const intl = useIntl();

  let options;
  let initialValue;
  if (isFrenzySearchEnabled && !isEmpty(searchApiFilters)) {
    ({ options } = sortConfig);
    initialValue = urlQueryParams.sort || FrenzySortOptions.BestMatch;
  } else {
    const { relevanceKey } = sortConfig;

    options = config.custom.sortConfig.options.map((option) => {
      const isRelevance = option.key === relevanceKey;
      return {
        ...option,
        disabled:
          (isRelevance && !isConflictingFilterActive) ||
          (!isRelevance && isConflictingFilterActive),
      };
    });

    const defaultValue = 'pub_updatedAt';
    initialValue = isConflictingFilterActive
      ? relevanceKey
      : (urlQueryParams.sort as string | undefined) || defaultValue;
  }

  const handleSubmit = (values: { [name: string]: string }) => {
    const usedValue = values ? values.sort : values;
    onSubmit(formatQueryParam(usedValue, queryParamName));
  };
  const selectedOption = initialValue ? optionLabel(options, initialValue as string) : undefined;

  return (
    <>
      {showAsPopup ? (
        <box mx="{1}">
          <filterpopupv2 popupClassName="{css.popup}" label="{" <TypographyWrapper="" variant="body1">
                {`${intl.formatMessage({ id: 'SortBy.heading' })} ${selectedOption}`}
              
            }
            id="SortByRadioDropdown"
            showAsPopup={showAsPopup}
            onSubmit={handleSubmit}
            initialValues={initialValue ? { sort: initialValue } : undefined}
            keepDirtyOnReinitialize
          >
            <groupoffieldradiobuttons className="{css.fieldGroup}" name="sort" id="SortyByRadioDropdown.radio-group" options="{options}"></groupoffieldradiobuttons>
          </filterpopupv2>
        </box>
      ) : (
        <filterplain label="{`${intl.formatMessage({" id:="" 'SortBy.heading'="" })}="" ${selectedOption}`}="" isSelected="{!!initialValue}" id="SortByRadioDropdown" liveEdit="" onSubmit="{handleSubmit}" initialValues="{initialValue" ?="" {="" sort:="" initialValue="" }="" :="" undefined}="">
          <groupoffieldradiobuttons className="{css.fieldGroupPlain}" name="sort" id="SortyByRadioDropdown.radio-group" options="{options}"></groupoffieldradiobuttons>
        </filterplain>
      )}
    </>
  );
};

export default SortByRadio;
</any></sortbyradioprops></groupoffieldradiobuttonsprops>