import React from 'react';
import {
  KeywordFilter,
  PriceFilter,
  SelectMultipleFilter,
  SelectSingleFilter,
} from '../../components';
import FilterCheckbox from '../../components/FilterCheckbox/FilterCheckbox';
import SortByRadio from '../../components/SortByRadio/SortByRadio';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { SearchFilterType } from '../../types/filters/filters';
import { FrenzyApiModes } from '../../types/frenzy/query';

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = (props) => {
  const {
    idPrefix,
    filterConfig,
    urlQueryParams,
    getInitialValues,
    getHandleChangedValueFn,
    onFilterChange,
    ...rest
  } = props;

  const { currencyConfig } = useBrandCountryConfig();

  const { id, type, queryParamNames, label, config } = filterConfig;
  const { liveEdit, showAsPopup } = rest;

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${id.toLowerCase()}`;
  const name = id.replace(/\s+/g, '-').toLowerCase();

  switch (type) {
    case SearchFilterType.SelectSingleFilter: {
      return (
        <selectsinglefilter id="{componentId}" label="{label}" queryParamNames="{queryParamNames}" initialValues="{getInitialValues(queryParamNames)}" onSelect="{getHandleChangedValueFn(useHistoryPush," FrenzyApiModes.FilterChange)}="" {...config}="" {...rest}=""></selectsinglefilter>
      );
    }
    case SearchFilterType.SelectMultipleFilter: {
      return (
        <selectmultiplefilter id="{componentId}" label="{label}" name="{name}" queryParamNames="{queryParamNames}" initialValues="{getInitialValues(queryParamNames)}" onSubmit="{getHandleChangedValueFn(useHistoryPush," FrenzyApiModes.FilterChange)}="" {...config}="" {...rest}=""></selectmultiplefilter>
      );
    }
    case SearchFilterType.PriceFilter: {
      return (
        <pricefilter id="{componentId}" label="{label}" queryParamNames="{queryParamNames}" initialValues="{getInitialValues(queryParamNames)}" onSubmit="{getHandleChangedValueFn(useHistoryPush)}" currencyConfig="{currencyConfig}" {...config}="" {...rest}=""></pricefilter>
      );
    }
    case SearchFilterType.KeywordFilter:
      return (
        <keywordfilter id="{componentId}" label="{label}" name="{name}" queryParamNames="{queryParamNames}" initialValues="{getInitialValues(queryParamNames)}" onSubmit="{getHandleChangedValueFn(useHistoryPush," FrenzyApiModes.RawQuery)}="" {...config}="" {...rest}=""></keywordfilter>
      );

    // Treet V2 Filters below
    case SearchFilterType.Checkbox:
      return (
        <filtercheckbox label="{label}" filters="{[filterConfig]}" getInitialValues="{getInitialValues}" onSubmit="{onFilterChange}" {...rest}=""></filtercheckbox>
      );
    case SearchFilterType.Sort:
      return (
        <sortbyradio urlQueryParams="{urlQueryParams}" sortConfig="{filterConfig}" onSubmit="{onFilterChange}" showAsPopup="" {...rest}=""></sortbyradio>
      );
    default:
      return null;
  }
};

export default FilterComponent;
