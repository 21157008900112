/* eslint-disable no-undef */
import { omit } from 'lodash';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import FilterComponent from '../../containers/LandingPageV2/FilterComponent';
import { validFilterParams } from '../../containers/LandingPageV2/LandingPageV2.helpers';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { Filter, FilterId, SortConfig } from '../../types/filters/filters';
import { createResourceLocatorString } from '../../util/routes';
import { getSearchInitialValuesFn } from '../../util/search';
import FiltersModal from '../FiltersModal/FiltersModal';
import { parse } from '../../util/urlHelpers';

import css from './FiltersComponentMobile.module.css';
import { NO_SCROLL } from '../../Routes';
import { useShopConfig } from '../../hooks/shopConfig';
import { addSubLabelsToFilterOptions } from '../../util/filter';

interface FiltersComponentMobileProps {
  urlQueryParams: { [key: string]: string[] | string | undefined };
  filters: Filter[];
  currentQueryParams: { [key: string]: string[] | string | undefined };
  toggleIsMobileModalOpen: () => void;
  setCurrentQueryParams: (params: any) => void;
  onFilterChange: (values: { [key: string]: string | null }) => void;
  sortConfig: SortConfig;
  isConflictingFilterActive: boolean;
  totalItems: number;
}

const FiltersComponentMobile: FC<filterscomponentmobileprops> = (props) => {
  const {
    urlQueryParams,
    filters,
    currentQueryParams,
    toggleIsMobileModalOpen,
    setCurrentQueryParams,
    onFilterChange,
    sortConfig,
    isConflictingFilterActive,
    totalItems,
  } = props;

  const routes = useRouteConfiguration();
  const history = useHistory();
  const { shopName } = useShopConfig();

  // Selected aka active filters
  const selectedFilters = validFilterParams(
    urlQueryParams,
    filters.filter((f) => f.id !== FilterId.Size && f.id !== FilterId.Keyword)
  );

  const selectedFiltersCount = Object.keys(selectedFilters).length;

  const onResetAll = () => {
    const filterQueryParamNames = filters.map((f) => f.queryParamNames);
    // Reset state
    setCurrentQueryParams({});

    // Reset routing params
    const queryParams = omit(urlQueryParams, [...(filterQueryParamNames as any), 'sort']);
    history.push(
      createResourceLocatorString('LandingPage', routes, {}, { ...queryParams, [NO_SCROLL]: true })
    );
  };

  const getSearchModeQueryParam = () => {
    const { ...searchInURL } = parse(history.location.search);

    if (searchInURL.mode) {
      return { mode: searchInURL.mode };
    }

    return {};
  };

  // sort filters to have Size be the first filter
  filters.sort((a, b) => {
    if (a.id === FilterId.Size && b.id !== FilterId.Size) return -1;
    if (a.id !== FilterId.Size && b.id === FilterId.Size) return 1;
    return 0;
  });

  // Combine sort and filters to display in combined modal for mobile
  const allFilters = sortConfig ? [sortConfig, ...filters] : filters;

  const searchModeQueryParam = getSearchModeQueryParam();

  return (
    <filtersmodal className="{css.searchFiltersMobile}" urlQueryParams="{{" ...searchModeQueryParam,="" ...urlQueryParams="" }}="" resultsCount="{totalItems}" toggleIsMobileModalOpen="{toggleIsMobileModalOpen}" onResetAll="{onResetAll}" selectedFilters="{selectedFilters}" selectedFiltersCount="{selectedFiltersCount}">
      {allFilters
        .filter((filter: Filter | SortConfig) => !!filter.searchNavBarSection)
        .map((filter: Filter | SortConfig) => {
          const filterToUse =
            filter.id === FilterId.IsBrandDirect
              ? addSubLabelsToFilterOptions(filter as Filter, shopName)
              : filter;

          return (
            <filtercomponent key="{`SearchFiltersMobile.${filter.id}`}" idPrefix="SearchFiltersMobile" filterConfig="{filterToUse}" urlQueryParams="{urlQueryParams}" getInitialValues="{getSearchInitialValuesFn(currentQueryParams," urlQueryParams)}="" onFilterChange="{onFilterChange}" liveEdit="" showAsPopup="{false}" isConflictingFilterActive="{isConflictingFilterActive}"></filtercomponent>
          );
        })}
    </filtersmodal>
  );
};

export default FiltersComponentMobile;
</filterscomponentmobileprops>