import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { Divider, OutsideClickHandler } from '..';
import { FilterForm } from '../../forms';
import { useShopConfig } from '../../hooks/shopConfig';
import { useTypographyStyles } from '../../hooks/useTypographyStyles';
import css from './FilterPopupV2.module.css';
import { useIsMobile } from '../../hooks/useIsMobile';
import {
  trackFiltersBarFilterClick,
  trackFiltersBarPopupClear,
  trackFiltersBarPopupFilterSubmit,
} from '../../util/heap';

const KEY_CODE_ESCAPE = 27;
interface FilterPopupV2Props {
  rootClassName?: string;
  className?: string;
  popupClassName?: string;
  id: string;
  label: string | ReactElement;
  labelMaxWidth?: number;
  isSelected?: boolean;
  children: ReactElement;
  initialValues?: { [name: string]: string[] | string };
  keepDirtyOnReinitialize?: boolean;
  showAsPopup?: boolean;
  onSubmit: (values: any) => void;
  onClear?: () => void;
}

const FilterPopupV2: FC<filterpopupv2props> = (props) => {
  const {
    rootClassName,
    className,
    popupClassName,
    id,
    label,
    labelMaxWidth,
    isSelected,
    children,
    initialValues,
    keepDirtyOnReinitialize,
    showAsPopup,
    onSubmit,
    onClear,
  } = props;

  const intl = useIntl();
  const isMobile = useIsMobile();
  const { styles, shopName } = useShopConfig();

  const [isOpen, setIsOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const popupRef = useRef<htmldivelement>(null);

  useEffect(() => {
    if (isOpen && popupRef.current && isMobile) {
      popupRef.current.focus();
      popupRef.current.scrollIntoView({ block: 'start' });
    }
  }, [isOpen, isMobile]);

  const shouldShowArrowDownIcon = label === 'Size';
  const hasNoSelectedValues = isEmpty(props.initialValues);
  const filterLabel = typeof label === 'string' ? label : id;

  const titleClasses = useTypographyStyles({
    defaultStyles: { marginRight: shouldShowArrowDownIcon ? '2px' : 0 },
    hoverStyles: { opacity: isSelected ? '1.0' : '0.6' },
    activeStyles: { opacity: isSelected ? '1.0' : '0.6' },
    selectedStyles: { color: 'white' },
    toggledStyles: { opacity: isSelected ? '1.0' : '0.6' },
  })();

  const clearButtonClasses = useTypographyStyles({
    defaultStyles: { color: hasNoSelectedValues ? styles.matterColorAnti : styles.matterColor },
    hoverStyles: { cursor: hasNoSelectedValues ? 'default' : 'pointer' },
    activeStyles: { color: styles.matterColor },
  })();

  const handleSubmit = (values: { [key: string]: string[] }) => {
    onSubmit(values);
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    trackFiltersBarPopupClear(filterLabel, shopName);
    onSubmit(null);
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  const toggleOpen = (enforcedState?: boolean) => {
    if (enforcedState) {
      setIsOpen(enforcedState);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleKeyDown = (e: any) => {
    // Gather all escape presses to close menu
    if (e.keyCode === KEY_CODE_ESCAPE) {
      toggleOpen(false);
    }
  };

  const handleFilterClose = () => {
    setIsOpen(false);
    trackFiltersBarPopupFilterSubmit(filterLabel, shopName);
  };

  const handleFilterClick = () => {
    toggleOpen();
    trackFiltersBarFilterClick(filterLabel, shopName);
  };

  const classes = classNames(rootClassName || css.root, className);
  const popupClasses = classNames(css.popup, { [css.isOpen]: isOpen }, popupClassName);
  const popupSizeClasses = css.popupSize;
  const desktopLabelStyles = isSelected ? css.labelSelected : css.label;
  const labelStyles = isMobile ? css.label : desktopLabelStyles;
  const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};
  const labelMaxWidthStyles = labelMaxWidth ? css.labelEllipsis : null;
  const clear = intl.formatMessage({ id: 'FilterForm.clear' });

  return (
    <outsideclickhandler onOutsideClick="{handleBlur}">
      <div className="{classes}" onKeyDown="{handleKeyDown}">
        <button className="{classNames(labelStyles," labelMaxWidthStyles)}="" style="{{" ...labelMaxWidthMaybe,="" display:="" 'flex',="" flexDirection:="" 'row',="" justifyContent:="" 'center',="" alignItems:="" }}="" onMouseEnter="{()" ==""> setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          onClick={handleFilterClick}
          type="button"
        >
          <typography *="" TODO:="" (HM="" |="" Treet-7753):="" update="" component="" to="" use="" TypographyWrapper="" variant="body1" noWrap="" style="{{" textTransform:="" 'none'="" }}="" className="{classNames(titleClasses.root," {="" toggled:="" isOpen,="" selected:="" isMobile="" ?="" false="" :="" isSelected,="" hover:="" isMouseOver,="" active:="" })}="">
            {label}
          </typography>
        </button>
        <div id="{id}" ref="{popupRef}" className="{popupClasses}">
          {isOpen && (
            <filterform id="{`${id}.form`}" paddingClasses="{popupSizeClasses}" showAsPopup="{showAsPopup}" initialValues="{initialValues}" keepDirtyOnReinitialize="{keepDirtyOnReinitialize}" liveEdit="" onChange="{handleSubmit}">
              <div className="{css.filterWrapper}">{children}</div>
              {!keepDirtyOnReinitialize && (
                <>
                  <divider className="{css.divider}"></divider>
                  <box display="flex" justifyContent="space-between" className="{css.footerWrapper}">
                    <button className="{css.actionButton}" type="button" onClick="{handleClear}" disabled="{hasNoSelectedValues}">
                      <typography variant="body1" className="{clearButtonClasses.root}">
                        {clear}
                      </typography>
                    </button>
                    <button className="{css.actionButton}" type="button" onClick="{handleFilterClose}">
                      <typography variant="body1">Done</typography>
                    </button>
                  </box>
                </>
              )}
            </filterform>
          )}
        </div>
      </div>
    </outsideclickhandler>
  );
};

export default FilterPopupV2;
</htmldivelement></filterpopupv2props>