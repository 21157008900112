import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'lodash';
import FilterPopupV2 from '../FilterPopupV2/FilterPopupV2';
import { useShopConfig } from '../../hooks/shopConfig';
import { parseSelectFilterOptions } from '../../util/search';
import { Filter, FilterId } from '../../types/filters/filters';
import { CheckboxGroup, FilterPlain } from '..';

import css from './FilterCheckbox.module.css';

const getQueryParamName = (queryParamNames: string[]) => queryParamNames[0];

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const formatQueryParam = (selectedOptions: string[] | undefined, queryParamName: string) => {
  const value =
    selectedOptions && selectedOptions.length > 0 ? `${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

// Formats all query params for any filters that have values passed in nameToValues
const getQueryParams = (filters: Filter[], nameToValues: { [name: string]: string[] }) =>
  filters.reduce((acc: { [name: string]: string | null }, filter: Filter) => {
    const { id, queryParamNames } = filter;
    const queryParamName = getQueryParamName(queryParamNames);
    const filterQueryParam = formatQueryParam(nameToValues[id], queryParamName);
    return { ...acc, ...filterQueryParam };
  }, {});

// Returns a mapping from the filter name to its corresponding values
const getNamedValues = (
  filters: Filter[],
  parseValues: (queryParamNames: any) => { [key: string]: string }
) =>
  filters.reduce((acc: { [name: string]: string[] }, filter: Filter) => {
    const { id, queryParamNames } = filter;
    const queryParamName = getQueryParamName(queryParamNames);
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const values = parseValues(queryParamNames);
    const filterOptions = values[queryParamName] || null;
    return { ...acc, [id]: parseSelectFilterOptions(filterOptions) };
  }, {});

interface FilterGroupTitleProps {
  filter: Filter;
}

const FilterGroupTitle: FC<filtergrouptitleprops> = (props) => {
  const { filter } = props;
  const { styles } = useShopConfig();

  return (
    <typography variant="subtitle1" style="{{" margin:="" '8px="" 0',="" color:="" styles.marketplaceColor="" }}="">
      By {filter.label}
    </typography>
  );
};

interface FilterCheckboxProps {
  className?: string;
  label: string | React.ReactElement;
  filters: Filter[];
  getInitialValues: (queryParamNames: any) => { [key: string]: string };
  onSubmit: (values: { [key: string]: string | null }) => void;
  showAsPopup: boolean;
}

const FilterCheckbox: FC<filtercheckboxprops> = (props) => {
  const { className, filters, label, getInitialValues, onSubmit, showAsPopup } = props;

  const { shopName } = useShopConfig();

  const handleSubmit = (values: { [name: string]: string[] }) => {
    const filterIds = filters.map((filter) => filter.id);
    const activeFilterIds = (values && filterIds.filter((id) => values[id])) || [];
    const activeFilterIdToValues = activeFilterIds.reduce(
      (acc: { [id: string]: string[] }, id: FilterId) => ({ ...acc, [id]: values[id] }),
      {}
    );
    const queryParams = getQueryParams(filters, activeFilterIdToValues);
    onSubmit(queryParams);
  };

  const hasInitialValues = !!filters.find((filter) => {
    const { queryParamNames } = filter;
    const queryParamName = getQueryParamName(queryParamNames);
    const initialValues = getInitialValues(queryParamNames);
    return !!initialValues?.[queryParamName];
  });

  // Pass the initial values with the name key so that they can be passed to the correct field
  const namedInitialValues = hasInitialValues ? getNamedValues(filters, getInitialValues) : {};

  const filterValues = filters.reduce((acc: any[], filter) => {
    const { options } = filter.config;
    return options ? [...acc, ...options] : acc;
  }, []);
  if (isEmpty(filterValues)) return null;

  if (showAsPopup) {
    return (
      <box mx="{1}">
        <filterpopupv2 className="{className}" label="{label}" isSelected="{hasInitialValues}" id="{`${label}.popup`}" showAsPopup="{showAsPopup}" onSubmit="{handleSubmit}" initialValues="{namedInitialValues}" keepDirtyOnReinitialize="{false}">
          <>
            {filters.map((filter) => {
              const { id, config } = filter;
              const title = filters.length > 1 ? <filtergrouptitle filter="{filter}"></filtergrouptitle> : undefined;
              return (
                <div key="{id}">
                  {title}
                  <checkboxgroup className="{css.fieldGroup}" name="{id}" id="{`${id}-checkbox-group`}" options="{config.options" ||="" []}=""></checkboxgroup>
                </div>
              );
            })}
          </>
        </filterpopupv2>
      </box>
    );
  }

  return (
    <filterplain className="{className}" label="{label}" isSelected="{hasInitialValues}" id="{`${label}.plain`}" liveEdit="" onSubmit="{handleSubmit}" initialValues="{namedInitialValues}" shopName="{shopName}">
      {filters.map((filter) => {
        const { id, config } = filter;
        return (
          <checkboxgroup className="{css.fieldGroupPlain}" name="{id}" id="{`${id}-checkbox-group`}" options="{config.options" ||="" []}="" key="{id}"></checkboxgroup>
        );
      })}
    </filterplain>
  );
};

export default FilterCheckbox;
</filtercheckboxprops></filtergrouptitleprops>