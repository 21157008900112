import React, { FC, ReactElement, RefObject, useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, BoxProps } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import { IconCloseV2, IconSearch, Navbar } from '../../components';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import { getSearchInitialValuesFn, isAnyFilterActive } from '../../util/search';
import { useShopConfig, useShopConfigV2 } from '../../hooks/shopConfig';
import { getHandleSearchChangedValueFn } from './LandingPageV2.helpers';
import { Filter, FilterId, SearchNavBarSections, SortConfig } from '../../types/filters/filters';
import { keywordFilterConfig } from '../../shopConfig/filters/marketplace-custom-config';
import FilterCheckbox from '../../components/FilterCheckbox/FilterCheckbox';
import FiltersComponentMobile from '../../components/FiltersComponentMobile/FiltersComponentMobile';
import { getKeywordQueryParam } from '../../components/KeywordFilter/KeywordFilter';
import SortByRadio from '../../components/SortByRadio/SortByRadio';
import AppContext from '../../context/AppContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useWindowSize } from '../../hooks/useWindowSize';
import { FrenzyApiModes } from '../../types/frenzy/query';
import FilterComponent from './FilterComponent';
import css from './LandingPageV2.module.css';
import { Feature } from '../../util/featureFlags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { addSubLabelsToFilterOptions } from '../../util/filter';

export const MAX_MEDIUM_SEARCH_NAVBAR_WIDTH = 1200;

interface SearchNavbarProps {
  toggleIsMobileModalOpen: () => void;
  urlQueryParams: { [key: string]: string[] | string | undefined };
  sortConfig: SortConfig;
  filters: Filter[];
  totalItems: number;
  elRef?: RefObject<htmldivelement>;
  topbarHeight: number | null;
}

interface SectionProps {
  wrapperBoxOverrides?: Partial<boxprops>;
}

interface SectionBaseProps {
  urlQueryParams: { [key: string]: string[] | string | undefined };
  onFilterChange: (values: { [key: string]: string | null }) => void;
}

interface FilterSectionProps extends SectionBaseProps {
  filters: Filter[];
  getInitialValues: (queryParamNames: any) => { [key: string]: string };
}

interface FilterProps extends FilterSectionProps {
  filterSection: SearchNavBarSections;
  className?: string;
}

interface SearchSectionProps extends FilterSectionProps {
  onSearchQueryChange: (values: { [key: string]: string | undefined }) => void;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  searchQueryInitialValue: string;
}

interface SortBySectionProps extends SectionBaseProps {
  sortConfig: SortConfig;
  isConflictingFilterActive: boolean;
}

interface CondensedFilterSectionProps extends SectionBaseProps {
  filters: Filter[];
  currentQueryParams: { [key: string]: string[] | string | undefined };
  toggleIsMobileModalOpen: () => void;
  setCurrentQueryParams: (value: any) => void;
  sortConfig: SortConfig;
  isConflictingFilterActive: boolean;
  totalItems: number;
}

interface CategoryFilterProps {
  filters: Filter[];
  getInitialValues: (queryParamNames: any) => { [key: string]: string };
  onFilterChange: (values: { [key: string]: string | null }) => void;
}

const Section: FC<sectionprops> = (props) => {
  const { children, wrapperBoxOverrides } = props;

  return (
    <box display="flex" alignItems="center" className="{css.section}" {...wrapperBoxOverrides}="">
      {children}
    </box>
  );
};

const SectionItem: FC<sectionprops> = (props) => {
  const { children, wrapperBoxOverrides } = props;

  return (
    <box mx="4px" {...wrapperBoxOverrides}="">
      {children}
    </box>
  );
};

const Filters = (props: FilterProps) => {
  const { filters, urlQueryParams, getInitialValues, onFilterChange, filterSection, className } =
    props;

  const { shopName } = useShopConfig();

  return (
    <box display="flex" flexDirection="row">
      {filters
        .filter((f: Filter) => f.searchNavBarSection === filterSection)
        .map((filter) => {
          const filterToUse =
            filter.id === FilterId.IsBrandDirect
              ? addSubLabelsToFilterOptions(filter as Filter, shopName)
              : filter;

          return (
            <filtercomponent key="{`SearchNavbar.${filter.id}`}" idPrefix="SearchNavbar" filterConfig="{filterToUse}" urlQueryParams="{urlQueryParams}" getInitialValues="{getInitialValues}" onFilterChange="{onFilterChange}" showAsPopup="" className="{className}"></filtercomponent>
          );
        })}
    </box>
  );
};

const CombinedFilters = (props: CategoryFilterProps) => {
  const { filters, getInitialValues, onFilterChange } = props;

  return (
    <box display="flex" flexDirection="column">
      <filtercheckbox label="Category" filters="{filters}" getInitialValues="{getInitialValues}" onSubmit="{onFilterChange}" showAsPopup=""></filtercheckbox>
    </box>
  );
};

const FilterSection = (props: FilterSectionProps) => {
  const { filters, urlQueryParams, getInitialValues, onFilterChange } = props;
  const isMobile = useIsMobile();

  const isCombinedFilter = (filter: Filter) => {
    const isFilterSection = filter.searchNavBarSection === SearchNavBarSections.Filter;
    const isCombinedFilterType = filter.id === FilterId.Category || filter.id === FilterId.Style;
    return isFilterSection && isCombinedFilterType;
  };

  const combinedFilters = filters.filter((f) => isCombinedFilter(f));
  const independentFilters = filters.filter((f) => !isCombinedFilter(f));
  const sizeFilter = independentFilters.filter((f) => f.id === FilterId.Size);
  // for mobile we want to limit the amount of filters shown on the filter bar
  const otherIndependentFilters = isMobile
    ? independentFilters.filter((f) => f.id === FilterId.Condition)
    : independentFilters.filter((f) => f.id !== FilterId.Size);

  return (
    <section>
      <sectionitem>
        <box display="flex" flexDirection="row">
          {!isEmpty(sizeFilter) && (
            <filtercomponent key="{`SearchNavbar.${sizeFilter[0].id}`}" idPrefix="SearchNavbar" filterConfig="{sizeFilter[0]}" urlQueryParams="{urlQueryParams}" getInitialValues="{getInitialValues}" onFilterChange="{onFilterChange}" showAsPopup=""></filtercomponent>
          )}
          {!isEmpty(combinedFilters) && (
            <combinedfilters filters="{combinedFilters}" getInitialValues="{getInitialValues}" onFilterChange="{onFilterChange}"></combinedfilters>
          )}
          <filters filters="{otherIndependentFilters}" urlQueryParams="{urlQueryParams}" getInitialValues="{getInitialValues}" onFilterChange="{onFilterChange}" filterSection="{SearchNavBarSections.Filter}"></filters>
        </box>
      </sectionitem>
    </section>
  );
};

const SearchSection = (props: SearchSectionProps) => {
  const {
    filters,
    urlQueryParams,
    getInitialValues,
    onFilterChange,
    onSearchQueryChange,
    searchQuery,
    setSearchQuery,
    searchQueryInitialValue,
  } = props;

  const isSearchModalEnabled = useFeatureFlags(Feature.SearchBarV2);
  const isMobile = useIsMobile();
  const urlParam = getKeywordQueryParam(keywordFilterConfig.queryParamNames);

  const handleSearch = (query: string) => {
    onSearchQueryChange({ [urlParam]: query !== '' ? query : undefined });
  };

  const handleCancelSearch = () => {
    setSearchQuery('');
    onSearchQueryChange({ [urlParam]: undefined });
  };

  const placeholderText = isMobile ? 'Search' : 'Search by style, color, etc.';
  const placeholderStyle = isMobile ? {} : { width: '100%', minWidth: '15em' };

  return (
    <section wrapperBoxOverrides="{{" justifyContent:="" 'center'="" }}="">
      <sectionitem 0="" wrapperBoxOverrides="{{" mx:="" }}="">
        <filters filters="{filters}" urlQueryParams="{urlQueryParams}" getInitialValues="{getInitialValues}" onFilterChange="{onFilterChange}" filterSection="{SearchNavBarSections.Search}" className="{css.searchSectionItem}"></filters>
      </sectionitem>
      {!isSearchModalEnabled && (
        <sectionitem wrapperBoxOverrides="{placeholderStyle}">
          <searchbar placeholder="{placeholderText}" value="{searchQueryInitialValue" ||="" searchQuery}="" onChange="{(newValue)" ==""> setSearchQuery(newValue)}
            onRequestSearch={() => handleSearch(searchQuery)}
            onCancelSearch={handleCancelSearch}
            cancelOnEscape
            classes={{
              root: css.searchSectionItem,
              iconButton: css.iconButton,
            }}
            style={{
              boxShadow: 'none',
              borderRadius: '0',
              height: '39px',
            }}
            searchIcon={<iconsearch></iconsearch>}
            closeIcon={<iconclosev2></iconclosev2>}
          />
        </searchbar></sectionitem>
      )}
    </section>
  );
};

const SortBySection = (props: SortBySectionProps) => {
  const { sortConfig, urlQueryParams, isConflictingFilterActive, onFilterChange } = props;

  return (
    <section wrapperBoxOverrides="{{" justifyContent:="" 'flex-end'="" }}="">
      <sectionitem>
        <sortbyradio urlQueryParams="{urlQueryParams}" sortConfig="{sortConfig}" onSubmit="{onFilterChange}" isConflictingFilterActive="{isConflictingFilterActive}" showAsPopup=""></sortbyradio>
      </sectionitem>
    </section>
  );
};

const CondensedFilterSection = (props: CondensedFilterSectionProps) => {
  const {
    filters,
    urlQueryParams,
    onFilterChange,
    currentQueryParams,
    toggleIsMobileModalOpen,
    setCurrentQueryParams,
    sortConfig,
    isConflictingFilterActive,
    totalItems,
  } = props;

  return (
    <section>
      <sectionitem>
        <filterscomponentmobile urlQueryParams="{urlQueryParams}" filters="{filters}" currentQueryParams="{currentQueryParams}" toggleIsMobileModalOpen="{toggleIsMobileModalOpen}" setCurrentQueryParams="{setCurrentQueryParams}" onFilterChange="{onFilterChange}" sortConfig="{sortConfig}" isConflictingFilterActive="{isConflictingFilterActive}" totalItems="{totalItems}"></filterscomponentmobile>
      </sectionitem>
    </section>
  );
};

const SearchNavbar: FC<searchnavbarprops> = (props) => {
  const {
    toggleIsMobileModalOpen,
    urlQueryParams,
    sortConfig,
    filters,
    totalItems,
    elRef,
    topbarHeight,
  } = props;

  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const { treetId } = useContext(AppContext);

  const history = useHistory();
  const { shopName } = useShopConfigV2();

  const windowSize = useWindowSize();
  const isMobile = useIsMobile();

  let styleOverride = {};

  if (topbarHeight) {
    // override css top property for searchNavBar to account for banner heights
    styleOverride = {
      top: topbarHeight,
    };
  }

  const shouldShrinkSearchNavbar =
    isMobile || (!!windowSize.width && windowSize.width < MAX_MEDIUM_SEARCH_NAVBAR_WIDTH);

  const [currentQueryParams, setCurrentQueryParams] = useState(urlQueryParams);
  const [searchQuery, setSearchQuery] = useState('');
  const isSearchBarV2Enabled = useFeatureFlags(Feature.SearchBarV2);

  const getInitialValues = getSearchInitialValuesFn(currentQueryParams, urlQueryParams);
  const urlParam = getKeywordQueryParam(keywordFilterConfig.queryParamNames);
  const searchQueryInitialValue = getInitialValues([urlParam])?.[urlParam];

  useEffect(() => {
    setCurrentQueryParams(urlQueryParams);
    setSearchQuery(searchQueryInitialValue);
  }, [urlQueryParams]);
  const useHistoryPush = true;
  const handleSearchChangedValueFn = getHandleSearchChangedValueFn({
    urlQueryParams,
    currentQueryParams,
    currentUser,
    setCurrentQueryParams,
    sortConfig,
    filterConfig: filters,
    history,
    shopName,
    treetId,
  });
  const onFilterChange = handleSearchChangedValueFn(useHistoryPush, FrenzyApiModes.FilterChange);
  const onFilterChangeNoScroll = handleSearchChangedValueFn(
    useHistoryPush,
    FrenzyApiModes.FilterChange,
    true
  );
  const onSearchQueryChange = handleSearchChangedValueFn(useHistoryPush, FrenzyApiModes.RawQuery);

  const isConflictingFilterActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    urlQueryParams,
    filters
  );

  const filterSection = (
    <filtersection key="filters" filters="{filters}" urlQueryParams="{urlQueryParams}" getInitialValues="{getInitialValues}" onFilterChange="{onFilterChange}"></filtersection>
  );

  const searchSection = (
    <searchsection key="search" filters="{filters}" urlQueryParams="{urlQueryParams}" getInitialValues="{getInitialValues}" onFilterChange="{onFilterChange}" onSearchQueryChange="{onSearchQueryChange}" searchQuery="{searchQuery}" setSearchQuery="{setSearchQuery}" searchQueryInitialValue="{searchQueryInitialValue}"></searchsection>
  );

  const sortBySection = (
    <sortbysection key="sort-by" sortConfig="{sortConfig}" urlQueryParams="{urlQueryParams}" isConflictingFilterActive="{isConflictingFilterActive}" onFilterChange="{onFilterChange}"></sortbysection>
  );

  const condensedFilterSection = (
    <condensedfiltersection key="condensed-filter" filters="{filters}" urlQueryParams="{urlQueryParams}" onFilterChange="{onFilterChangeNoScroll}" currentQueryParams="{currentQueryParams}" toggleIsMobileModalOpen="{toggleIsMobileModalOpen}" setCurrentQueryParams="{setCurrentQueryParams}" sortConfig="{sortConfig}" isConflictingFilterActive="{isConflictingFilterActive}" totalItems="{totalItems}"></condensedfiltersection>
  );

  let sections: ReactElement[] = [];

  if (isSearchBarV2Enabled) {
    sections = shouldShrinkSearchNavbar
      ? [filterSection, condensedFilterSection]
      : [filterSection, sortBySection];
  } else {
    sections = shouldShrinkSearchNavbar
      ? [searchSection, condensedFilterSection]
      : [filterSection, searchSection, sortBySection];
  }

  return (
    <div ref="{elRef}" id="search" style="{styleOverride}" className="{css.searchNavbar}">
      <navbar wrapperBoxOverrides="{{" justifyContent:="" 'space-between',="" overflow:="" 'visible',="" position:="" 'relative',="" }}="">
        {sections}
      </navbar>
    </div>
  );
};

export default SearchNavbar;
</any></searchnavbarprops></sectionprops></sectionprops></boxprops></htmldivelement>