import { Box, BoxProps } from '@material-ui/core';
import React, { FC, ReactElement } from 'react';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getDarkerBrandCssBackgroundColor } from '../../util/colors/colors';
import IconButton from '../Icons/IconButton/IconButton';
import IconCloseV2 from '../Icons/IconCloseV2/IconCloseV2';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';

import css from './Banner.module.css';

interface BannerProps {
  bodyText: string;
  actionText: string;
  icon: ReactElement;
  onActionClick: () => void;
  onClose?: () => void;
}

const Banner: FC<bannerprops &="" BoxProps=""> = (props) => {
  const { bodyText, actionText, icon, onClose, onActionClick, ...boxProps } = props;
  const { css: brandCss } = useShopConfig();
  const backgroundColor = getDarkerBrandCssBackgroundColor(brandCss);
  const isMobile = useIsMobile();

  const handleCloseIconClick = () => {
    if (onClose) onClose();
  };

  const handleActionClick = () => {
    onActionClick();
  };

  return (
    <box display="block" position="relative" {...boxProps}="">
      <box bgcolor="{backgroundColor}" p="{2}" className="{isMobile" ?="" css.pageBannerContainerMobile="" :="" css.pageBannerContainer}="" onClick="{handleActionClick}" style="{{" cursor:="" 'pointer',="" }}="">
        {icon}
        <box display="flex" flexDirection="{{" xs:="" 'column',="" sm:="" 'row'="" }}="" alignItems="center" justifyContent="center">
          <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline',="" ...(isMobile="" ?="" {="" style:="" fontSize:="" '12px'="" }="" :="" paddingRight:="" '4px'="" }),="" }}="">
            {bodyText}
          </typographywrapper>
          <button type="button" className="{css.bannerAction}">
            <typographywrapper variant="body1" typographyOverrides="{{" ...(isMobile="" ?="" {="" style:="" fontSize:="" '12px'="" }="" :="" {})="" }}="">
              {actionText}
            </typographywrapper>
          </button>
        </box>
      </box>
      {onClose && (
        <box 1="" p="{isMobile" ?="" :="" 2}="" className="{css.bannerCloseButton}">
          <iconbutton icon="{<IconCloseV2"></iconbutton>} onClick={handleCloseIconClick} />
        </box>
      )}
    </box>
  );
};

export default Banner;
</bannerprops>