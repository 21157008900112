import classNames from 'classnames';
import React, { FC, RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { Builder } from '@builder.io/react';
import { TopbarContainer } from '..';
import {
  BuilderSection,
  Button,
  ConditionalWrapper,
  Footer,
  IconArrowDown,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  SectionFooterActionV2,
  SubscribeForm,
  TypographyWrapper,
} from '../../components';
import SectionHeroV2 from '../../components/SectionHeroV2/SectionHeroV2';
import { useShopConfig } from '../../hooks/shopConfig';
import { parse } from '../../util/urlHelpers';
import { AboutPageTab } from '../AboutPage/aboutPageUtils';
import {
  areSearchParamsInSync,
  pickSearchParamsOnly,
  validFilterParams,
} from './LandingPageV2.helpers';
import { RequestStatus } from '../../types/requestStatus';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import { sortConfigForFrenzy } from '../../util/frenzyHelpers';
import config, { LANDING_PAGE_SECTION } from '../../shopConfig/config';
import { ButtonVariant, InlineTextButton } from '../../components/Button/Button';
import { useIsMobile } from '../../hooks/useIsMobile';
import {
  fetchAndInsertSoldListings,
  LandingPageV2State,
  updateNotifications,
} from './LandingPageV2.duck';
import SearchNavbar from './SearchNavbar';
import SearchResults from './SearchResults';
import MultiButtonWrapper from '../../components/MultiButtonWrapper/MultiButtonWrapper';
import { UpdateGeneralSavedSearchEmailSubscriptionParams } from '../../types/models/savedSearch';
import windowScroll from '../../util/scrollHelpers';
import {
  Cadence,
  SavedSearchType,
  useSavedSearchByEmailLazyQuery,
} from '../../types/apollo/generated/types.generated';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import AppContext from '../../context/AppContext';
import { setStorageKey, SubscribeModalState } from '../../ducks/subscribeModal.duck';
import { getLocalStorage } from '../../util/localStorageHelpers';
import { useUserCountryConfig } from '../../hooks/useCountryConfig';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useWindowScroll } from '../../hooks/useWindowScroll';
import { BuilderSections } from '../../util/builder';
import { BuilderLandingPageContentResponseData } from '../../types/builder/builder';
import TopbarTransparencyContext from '../../context/TopbarTransparencyContext';
import { ModalState, ModalType, setActiveModal } from '../../ducks/modal.duck';
import css from './LandingPageV2.module.css';

const LANDING_PAGE_MODAL_TRIGGERED_STORAGE_KEY = 'landing_page_modal_triggered';
const TWENTY_SECONDS = 20000; // in ms
const DEFAULT_TRANSPARENT_TOPBAR_SCROLL_THRESHOLD = 50; // in pixels

const smoothScrollToSearchNav = (
  searchNavbarRef?: RefObject<htmldivelement>,
  navbarRef?: RefObject<htmldivelement>
) => {
  const calculateScrollHeight = () => {
    // Handles autoscrolling better when called from useEffect on render
    if (searchNavbarRef && navbarRef) {
      return (searchNavbarRef?.current?.offsetTop || 0) - (navbarRef?.current?.offsetHeight || 0);
    }

    // If no refs are present (i.e. from a builder component):
    const searchNav = document?.getElementById('search')?.offsetTop || 0;
    const navbar = document?.getElementById('topbar')?.offsetHeight || 0;

    return searchNav - navbar;
  };

  windowScroll({
    top: calculateScrollHeight(),
    behavior: 'smooth',
  });
};

const LearnMoreButton: FC = () => {
  const {
    copy: { learnMoreButtonText },
  } = useShopConfig();
  return (
    <namedlink name="AboutPage" params="{{" tab:="" AboutPageTab.Info="" }}="" style="{{" textDecoration:="" 'none'="">
      <button className="{css.button}" variant="{ButtonVariant.Secondary}" style="{{" width:="" '100%'="" }}="">
        {learnMoreButtonText}
      </button>
    </namedlink>
  );
};

const ShopAndLearnMoreButtonsDesktop: FC<{ handleShopClick?: () => void }> = (props) => {
  const { handleShopClick } = props;
  const {
    copy: { shopButtonText },
  } = useShopConfig();

  const hasSearchNav = !!document?.getElementById('search');

  const onShopButtonClick = () => {
    if (handleShopClick) return handleShopClick();
    if (hasSearchNav) return smoothScrollToSearchNav();
    return null;
  };

  return (
    <multibuttonwrapper>
      <react.fragment key="shop-button">
        <conditionalwrapper condition="{!hasSearchNav}" wrapper="{(children)" ==""> (
            <namedlink name="LandingPage" to="{{" search:="" 'mode="raw-query'" }}="">
              {children}
            </namedlink>
          )}
        >
          <button key="shop-button" style="{{" width:="" '100%'="" }}="" className="{css.button}" variant="{ButtonVariant.Secondary}" onClick="{onShopButtonClick}">
            {shopButtonText}
          </button>
        </conditionalwrapper>
      </react.fragment>
      <react.fragment key="learn-more-button">
        <learnmorebutton></learnmorebutton>
      </react.fragment>
    </multibuttonwrapper>
  );
};

const ShopButtonMobile: FC<{ handleShopClick?: () => void }> = (props) => {
  const { handleShopClick } = props;

  const {
    copy: { shopButtonText },
  } = useShopConfig();

  const hasSearchNav = !!document?.getElementById('search');

  const onShopButtonClick = () => {
    if (handleShopClick) return handleShopClick();
    if (hasSearchNav) return smoothScrollToSearchNav();
    return null;
  };

  return (
    <conditionalwrapper condition="{!hasSearchNav}" wrapper="{(children)" ==""> (
        <namedlink name="LandingPage" to="{{" search:="" 'mode="raw-query'" }}="">
          {children}
        </namedlink>
      )}
    >
      <inlinetextbutton style="{{" textDecoration:="" 'none'="" }}="" type="button" onClick="{onShopButtonClick}">
        <typographywrapper component="span" variant="h2" typographyOverrides="{{" style:="" {="" color:="" 'white',="" display:="" 'block'="" }="" }}="">
          {shopButtonText}
        </typographywrapper>
        <iconarrowdown color="white"></iconarrowdown>
      </inlinetextbutton>
    </conditionalwrapper>
  );
};

interface ActionHeroProps {
  navbarRef: RefObject<htmldivelement>;
  searchNavbarRef: RefObject<htmldivelement>;
}

const ActionHero: FC<actionheroprops> = (props) => {
  const { navbarRef, searchNavbarRef } = props;

  const isMobile = useIsMobile();

  const handleShopClick = () => smoothScrollToSearchNav(searchNavbarRef, navbarRef);

  if (isMobile) {
    return (
      <sectionherov2 actionEl="{<LearnMoreButton"></sectionherov2>}
        bottomActionEl={<shopbuttonmobile handleShopClick="{handleShopClick}"></shopbuttonmobile>}
        shouldAnimate
      />
    );
  }

  return (
    <sectionherov2 actionEl="{<ShopAndLearnMoreButtonsDesktop" handleShopClick="{handleShopClick}"></sectionherov2>}
      shouldAnimate
    />
  );
};

const ActionFooter: FC<{
  footerRef: RefObject<htmlheadingelement>;
}> = (props) => {
  const { footerRef } = props;

  const {
    landingPageLayoutOrder,
    copy: { subscribeTitle, subscribeSubtitle },
    marketingSettingsConfig: { hideSizesFromSubscribe = false } = {},
  } = useShopConfig();

  const dispatch = useDispatch();
  const { updateNotificationsStatus } = useSelector<any>(
    (state) => state.LandingPageV2
  ) as LandingPageV2State;

  if (!landingPageLayoutOrder.includes(LANDING_PAGE_SECTION.FOOTER)) return null;
  const descriptionCopy =
    subscribeSubtitle ||
    'Get updates for new releases, discounts, and personalized “just for you” collections from Treet.';
  const headerCopy = subscribeTitle || 'Nothing catching your eye?';

  const onSubscribe = (params: UpdateGeneralSavedSearchEmailSubscriptionParams) =>
    dispatch(updateNotifications(params));

  const headerEl = (
    <div ref="{footerRef}">
      <typographywrapper variant="h1" typographyOverrides="{{" style:="" {="" color:="" 'white',="" textAlign:="" 'center'="" }="" }}="">
        {headerCopy}
      </typographywrapper>
    </div>
  );

  let footerActionEl;
  if (updateNotificationsStatus === RequestStatus.Success) {
    footerActionEl = (
      <typography variant="h2" style="{{" color:="" 'white',="" textAlign:="" 'center',="" fontWeight:="" 'bold'="" }}="">
        Thank you, your email is subscribed!
      </typography>
    );
  } else {
    footerActionEl = (
      <box pt="{2}">
        <subscribeform onSubscribe="{onSubscribe}" areSizesEnabled="{!hideSizesFromSubscribe}"></subscribeform>
      </box>
    );
  }

  return (
    <sectionfooteractionv2 headerEl="{headerEl}" description="{descriptionCopy}" actionEl="{footerActionEl}"></sectionfooteractionv2>
  );
};

const LandingPageV2: FC = () => {
  const { treetId } = useContext(AppContext);

  const [builderData, setBuilderData] = useState<
    BuilderLandingPageContentResponseData | undefined
  >();
  const [shouldMaybeOpenSubscribeModal, setShouldMaybeOpenSubscribeModal] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [topbarHeight, setTopbarHeight] = useState<number |="" null="">(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useIsMobile();
  const scroll = useWindowScroll();
  const {
    treetShopName,
    images,
    filters: shopConfigFilters,
    shopName,
    builderConfig,
    landingPageTransparentTopbarScrollThreshold,
    copy,
    css: brandCss,
  } = useShopConfig();
  const {
    canShipFromUserCountry: canUserListInCountry,
    canShipToUserCountry,
    canShipWithinUserCountry,
  } = useUserCountryConfig();
  const { allowBuy, allowList } = useEnabledCustomerExperiences();

  const isFrenzySearchEnabled = useFeatureFlags(Feature.FrenzySearch);
  const isLandingPageSubscribeModalEnabled = useFeatureFlags(Feature.LandingPageSubscribeModal);

  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const { searchApiFilters, pagination, searchParams, searchListingsStatus, soldListingsStatus } =
    useSelector<any>((state) => state.LandingPageV2) as LandingPageV2State;
  const { activeModal } = useSelector<any>((state) => state.modal) as ModalState;
  const { authInfoLoaded, isAuthenticated } = useSelector<any>((state) => state.Auth) as any;
  const { storageKey } = useSelector<any>((state) => state.subscribeModal) as SubscribeModalState;

  useEffect(() => {
    if (
      searchListingsStatus === RequestStatus.Success &&
      soldListingsStatus === RequestStatus.Ready
    ) {
      dispatch(fetchAndInsertSoldListings());
    }
  }, [searchListingsStatus]);

  const [
    querySavedSearchByEmail,
    { loading: isQuerySavedSearchesLoading, data: querySavedSearchesResponse },
  ] = useSavedSearchByEmailLazyQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (currentUser) {
      const { email } = currentUser.attributes;
      querySavedSearchByEmail({ variables: { email, treetId, type: SavedSearchType.General } });
    }
  }, [currentUser]);

  const canUserBuyInCountry = canShipToUserCountry || canShipWithinUserCountry;
  const shouldShowCountryWarningModal =
    (allowBuy && !canUserBuyInCountry) || (allowList && !canUserListInCountry);

  useEffect(() => {
    // If we display the country warning modal, return early so that we don't overload the user
    // with modals
    if (shouldShowCountryWarningModal) return;

    if (!isLandingPageSubscribeModalEnabled) return;
    if (!authInfoLoaded || isQuerySavedSearchesLoading) return;

    if (isAuthenticated) {
      // User is logged in but we haven't finished fetching their saved searches
      if (!querySavedSearchesResponse) return;

      const savedSearchCadence = querySavedSearchesResponse.savedSearch?.cadence;
      if (savedSearchCadence && savedSearchCadence !== Cadence.Never) return;
    }

    const localStorageValue = getLocalStorage(LANDING_PAGE_MODAL_TRIGGERED_STORAGE_KEY) || '';
    if (localStorageValue.includes(treetId)) return;

    // User might view landing page multiple times before the modal appears; we only want it
    // to show the first time so we shouldn't take action any subsequent time
    const hasTriggeredLandingPageModal = storageKey === LANDING_PAGE_MODAL_TRIGGERED_STORAGE_KEY;
    if (hasTriggeredLandingPageModal) return;

    if (activeModal == null) {
      dispatch(setStorageKey(LANDING_PAGE_MODAL_TRIGGERED_STORAGE_KEY));
      setTimeout(() => setShouldMaybeOpenSubscribeModal(true), TWENTY_SECONDS);
    }
  }, [authInfoLoaded, isQuerySavedSearchesLoading, isAuthenticated, querySavedSearchesResponse]);

  useEffect(() => {
    if (shouldMaybeOpenSubscribeModal) {
      if (activeModal == null) {
        dispatch(setActiveModal(ModalType.Subscribe));
      }
      setShouldMaybeOpenSubscribeModal(false);
    }
  }, [shouldMaybeOpenSubscribeModal]);

  // Begin Auto-Scroll behavior
  const actionFooterRef = useRef<htmlheadingelement>(null);
  const navbarRef = useRef<htmldivelement>(null);
  const searchNavbarRef = useRef<htmldivelement>(null);

  const locationState = location.state as { scrollOnRender?: boolean } | undefined;

  // calculate topbar height to know how much to offset css top property of searchNavBar
  /* eslint-disable consistent-return */
  useEffect(() => {
    const navbar = navbarRef.current;
    if (navbar) {
      const resizeObserver = new ResizeObserver((entries) => {
        /* eslint-disable no-restricted-syntax */
        for (const entry of entries) {
          const target = entry.target as HTMLElement;
          setTopbarHeight(target.offsetHeight);
        }
        /* eslint-enable no-restricted-syntax */
      });

      resizeObserver.observe(navbar);

      return () => {
        resizeObserver.unobserve(navbar);
      };
    }
  }, [navbarRef]);
  /* eslint-enable consistent-return */

  useEffect(() => {
    if (locationState?.scrollOnRender) {
      smoothScrollToSearchNav(searchNavbarRef, navbarRef);
    }
  }, [locationState]);
  // End Auto-Scroll behavior

  const sortConfig = isFrenzySearchEnabled ? sortConfigForFrenzy : config.custom.treetV2SortConfig;
  const filters = searchApiFilters || shopConfigFilters;

  const { ...searchInURL } = parse(location.search);
  const urlQueryParams = pickSearchParamsOnly(searchInURL, filters, sortConfig);

  const searchParamsAreInSync = areSearchParamsInSync(
    urlQueryParams,
    searchParams,
    filters,
    sortConfig
  );
  const hasSearchParamsPresent = searchInURL?.mode || searchInURL?.page;
  const hasPaginationInfo = !!pagination && pagination.totalItems != null;
  const searchInProgress = searchListingsStatus === RequestStatus.Pending;
  const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;
  const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination!.totalItems : 0;
  const page = searchParamsAreInSync && hasPaginationInfo ? pagination!.page : 1;
  const pageNumberTitleText = page > 1 ? `Page ${page} | ` : '';
  const pageNumberDescriptionText = page > 1 ? ` Search results page ${page}.` : '';

  const selectedFilters = validFilterParams(urlQueryParams, filters);
  const selectedFiltersCount = Object.keys(selectedFilters).length;

  const toggleIsMobileModalOpen = () => setIsMobileModalOpen(!isMobileModalOpen);

  const schemaTitle = copy?.seoSiteTitle || treetShopName;
  const schemaDescription =
    copy?.seoSiteDescription || copy?.heroSubtitle || `Buy and sell pre-loved ${shopName} items.`;
  const heroImageDefaultUrl = images?.heroImage?.url;
  const heroImageMobileUrl = images?.heroImageMobile?.url;
  const heroImageUrl = (isMobile && heroImageMobileUrl) || heroImageDefaultUrl;

  const landingPageBuilderSectionId = builderConfig?.sections?.[BuilderSections.LandingPageContent];

  // Hide these sections on load if brand has a builder.io custom page.
  const shouldShowFooter = !landingPageBuilderSectionId || builderData?.showFooter;
  const shouldShowSearchResults =
    !landingPageBuilderSectionId ||
    builderData?.showUnfilteredSearchResults ||
    hasSearchParamsPresent;

  const shouldShowHero = !hasSearchParamsPresent;

  // Topbar styles
  const isFullBleedTopbar = shouldShowHero && (brandCss?.bleedTopbarIntoHeroImage ?? false);
  const transparentTopbarScrollThreshold =
    landingPageTransparentTopbarScrollThreshold ?? DEFAULT_TRANSPARENT_TOPBAR_SCROLL_THRESHOLD;

  const isTopbarTransparent = isFullBleedTopbar && scroll.y < transparentTopbarScrollThreshold;

  // Set topbar class based on if a modal is open in
  // a child component
  const topbarClasses = classNames(css.topbar, {
    [css.topbarBehindModal]: isMobileModalOpen,
    [css.topbarAboveHero]: isFullBleedTopbar,
  });

  return (
    <page 314="" 630="" contentType="website" description="{`${schemaDescription}${pageNumberDescriptionText}`}" title="{`${pageNumberTitleText}${treetShopName}`}" TODO="" (SY|TREET-1008):="" Change="" sharing="" images="" to="" be="" a="" different="" image="" facebookImages="{[{" url:="" heroImageUrl,="" width:="" 1200,="" height:="" }]}="" twitterImages="{[{" 600,="" schema="{{" '@context':="" 'http:="" schema.org',="" '@type':="" 'WebPage',="" description:="" schemaDescription,="" name:="" schemaTitle,="" image:="" }}="">
      <layoutsinglecolumn>
        <layoutwrappertopbar className="{topbarClasses}" elRef="{navbarRef}">
          <topbartransparencycontext.provider value="{isTopbarTransparent}">
            <topbarcontainer></topbarcontainer>
          </topbartransparencycontext.provider>
        </layoutwrappertopbar>
        <layoutwrappermain>
          {!!landingPageBuilderSectionId && shouldShowHero && (
            <buildersection sectionType="{BuilderSections.LandingPageContent}" sectionId="{landingPageBuilderSectionId}" fetchDataFromBuilder="{(builderResponse)" ==""> setBuilderData(builderResponse)}
            />
          )}
          {!landingPageBuilderSectionId && shouldShowHero && (
            <actionhero navbarRef="{navbarRef}" searchNavbarRef="{searchNavbarRef}"></actionhero>
          )}
          {shouldShowSearchResults && (
            <>
              <searchnavbar toggleIsMobileModalOpen="{toggleIsMobileModalOpen}" urlQueryParams="{urlQueryParams}" sortConfig="{sortConfig}" filters="{filters}" totalItems="{totalItems}" elRef="{searchNavbarRef}" topbarHeight="{topbarHeight}"></searchnavbar>
              <searchresults listingsAreLoaded="{listingsAreLoaded}" totalItems="{totalItems}" areFiltersApplied="{selectedFiltersCount"> 0}
                subscribeBannerActionScrollToRef={actionFooterRef}
              />
            </>
          )}
          {shouldShowFooter && <actionfooter footerRef="{actionFooterRef}"></actionfooter>}
        </searchresults></buildersection></layoutwrappermain>
        <layoutwrapperfooter>
          <footer></footer>
        </layoutwrapperfooter>
      </layoutsinglecolumn>
    </page>
  );
};

Builder.registerComponent(ShopAndLearnMoreButtonsDesktop, {
  name: 'ShopAndLearnMoreButtonsDesktop',
});

// A simplified version of these two buttons, for Builder.
const ShopAndLearnMoreButtonsMobile = () => (
  <box>
    <learnmorebutton></learnmorebutton>
    <box mt="{3}" textAlign="center">
      <shopbuttonmobile></shopbuttonmobile>
    </box>
  </box>
);

Builder.registerComponent(ShopAndLearnMoreButtonsMobile, {
  name: 'ShopAndLearnMoreButtonsMobile',
});

export default LandingPageV2;
</htmldivelement></htmldivelement></htmlheadingelement></any></any></any></any></any></number></any></htmlheadingelement></actionheroprops></htmldivelement></htmldivelement></htmldivelement></htmldivelement>